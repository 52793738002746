<template>
	<div>
		<!-- <h1>Hola</h1> -->
		<!-- {{ speeds }} -->
		<div class="card p-2">
			<b-table-simple sticky-header="50vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>#</b-th>
						<b-th>Velocidad</b-th>
						<b-th>Por defecto</b-th>
						<b-th>Estado</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(speed, index) in speeds" :key="speed.id">
						<b-td>{{ index + 1 }}</b-td>
						<b-td>{{ speed.speed }}</b-td>
						<b-td>
							<div class="d-flex align-items-center justify-content-center">
								<b-form-checkbox
									@change="updateSpeedVideoDefault(speed)"
									:disabled="speed.speed_default || !speed.check"
									v-model="speed.speed_default"
									:key="sum"
								>
									<!-- {{ speed.speed_default }} -->
								</b-form-checkbox>
							</div>
						</b-td>
						<b-td>
							<div class="d-flex align-items-center justify-content-center">
								<!-- {{ [].length }} -->
								<b-form-checkbox
									:disabled="speeds.filter((it) => it.check).length == 1 && speed.check"
									@change="update(speed, index)"
									v-model="speed.check"
								>
									<!-- {{ speed.check }} -->
								</b-form-checkbox>
							</div>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>
	</div>
</template>

<script>
import configVideoService from "@/services/configVideoService"
import { mapActions, mapState, mapMutations } from "vuex"

export default {
	name: "asd",
	data() {
		return {
			speeds: [],
			sum: 0,
		}
	},
	mounted() {
		this.init()
	},
	computed: {
		...mapState("video", ["playbackRates"]),
	},
	methods: {
		...mapMutations("video", ["M_SET_PLAYBACKRATES"]),
		async init() {
			if (this.currentUser) {
				const { data } = await configVideoService.index(null)
				this.speeds = data.map((it) => ({
					...it,
					check: it.check == 1 ? true : false,
					speed_default: it.speed_default == 1 ? true : false,
				}))

				this.M_SET_PLAYBACKRATES(data)
			}
		},
		async update(settings, index) {
			// console.log('default', );
			if (this.speeds.filter((it) => it.speed_default)[0].id == settings.id) {
				this.showToast(
					"warning",
					"top-right",
					"Videos",
					"WarningIcon",
					"No se puede cambiar el estado a una velocidad marcada por defecto."
				)
				this.sum++

				this.speeds[index].check = !this.speeds[index].check

				return
			}

			this.isPreloading()
			await configVideoService.updateSpeedVideoStatus(settings.id)
			await this.init()
			this.isPreloading(false)
		},

		async updateSpeedVideoDefault(settings) {
			// console.log("ADD", settings);
			this.isPreloading()
			await configVideoService.updateSpeedVideoDefault(settings.id)
			await this.init()
			this.isPreloading(false)
		},

		//
	},
}
</script>
